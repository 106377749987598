import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import Img from "gatsby-image";
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import brandLogos from "../settings/company_brand.json";
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'

const FAQPage = () => {
 
  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'How To Sell Your House or Land in Sacramento | FAQ'}
        description={'Do you want to sell your house or land in Sacramento? If so, you may have some questions about how a direct sale works. Get all the answers you need here!'}
        keywords={''}
      />

      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">
            <h1>Frequently Asked Questions</h1>
            <p>Do you want to <a href="/sell-your-house/" data-type="page">sell your house or land</a> in Sacramento? If so, you may have some questions about selling your property directly. We get a lot of questions from the sellers we work with and we have answered the most popular ones below. <span>If you have a different question, don’t hesitate to <a href="/contact-us/">contact us</a> or give us a call at 916-237-9457!</span></p>

            <p><strong>Q: &nbsp;Do you list houses or land, or buy them outright?</strong><br/> 
              A:&nbsp;&nbsp; At Sell Your Home For Cash Sacramento, we buy houses and land outright. We are not listing properties on the MLS or charging commissions. In fact, we are so serious about buying your property that we often offer a $5,000 non-refundable deposit when you accept our offer. 
            </p>

            <p><strong>Q: &nbsp;How do you determine what to offer for a house or land?</strong><br/> 
              A:&nbsp; To determine our offer prices, we look at comparable sales in the area and factor in any repair or development costs. We aim to make a fair and direct offer, without the usual delays or fees. 
            </p>

            <p><strong>Q: &nbsp;Where do you buy houses or land?</strong><br/> 
              A:&nbsp; We currently buy property in Sacramento, Carmichael, Del Paso Heights, North Highlands, Oak Park, Fair Oaks, Citrus Heights, Arden-Arcade, Roseville, Orangevale, and West Sacramento. 
            </p>

            <p><strong>Q: &nbsp;Are there any costs I should know about?</strong><br/> 
              A:&nbsp; Never. We don’t charge any fees or commissions. We are direct buyers, ready to pay you cash for your house or land right away. In many cases, we’ll also include a $5,000 non-refundable deposit. 
            </p>

            <p><strong>Q: &nbsp;What situations are you able to help with?</strong><br/> 
              A:&nbsp; After being in real estate for over 10 years, we've seen it all—foreclosure, probate, bad tenants, costly repairs, or land sitting unused. Whatever your situation, we’re here to help. 
            </p>

            <p><strong>Q: &nbsp;What can I expect after providing my information?</strong><br/> 
              A:&nbsp; Once we receive your information, we’ll begin our research immediately. We look at comparable sales and local trends to create a fair, no-obligation offer for your property—whether it’s a house or a piece of land. 
            </p>
          </div>

          <div className="w-full md:w-1/3 md:pl-4">
            <SeeTheDifferenceSidebar />

            <div className="rounded-xl mb-2 shadow bg-white mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p className="text-gray-600 mb-2 leading-6 mt-1">
                  We buy houses and land in any condition — no commissions, no fees, and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.
                </p>
              </div>
              <div className="p-8 pt-4">
                <ContactForm stacked={true} hideLabels={true} showPlaceholders={true} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <HowItWorks />
    </Layout>
  );
};

export default FAQPage;
